import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Coordinates} from '@parashift/shared/models';

export interface PageAndCoordinates {
  coordinates: Coordinates;
  page_number: number;
}

@Injectable({
  providedIn: 'root',
})
export class DocumentTypeHelperService {

  private currentDocumentTypeId: string;

  private _setCoordinates = new Subject<PageAndCoordinates>();
  setCoordinates$ = this._setCoordinates.asObservable();

  private _setSubtitle = new Subject<string>();
  setSubtitle$ = this._setSubtitle.asObservable();

  disableForm = false;

  checkCurrentDocumentType(id: string) {
    if (!this.currentDocumentTypeId || id !== this.currentDocumentTypeId) {
      this.currentDocumentTypeId = id;
    }
  }

  setCoordinates(coordinates: Coordinates | undefined, page_number: number) {
    let roundedCoordinates = null;

    if (coordinates) {
      const decimalPlaces = 2;
      // + 2 as our coords from BE come with values > 1, while the user works with percent values from 0-100 => retains precision
      const decimalsForPercent = decimalPlaces + 2;

      roundedCoordinates = {
        top: parseFloat(coordinates.top.toFixed(decimalsForPercent)),
        right: parseFloat(coordinates.right.toFixed(decimalsForPercent)),
        bottom: parseFloat(coordinates.bottom.toFixed(decimalsForPercent)),
        left: parseFloat(coordinates.left.toFixed(decimalsForPercent)),
      };
    }

    this._setCoordinates.next({ coordinates: (roundedCoordinates as Coordinates) || coordinates, page_number });
  }

  setSubtitle(subtitle: string) {
    this._setSubtitle.next(subtitle);
  }
}
